import React from 'react';
import athirudramHeaderBanner from '../assets/img/athirudram-headerbanner.png';
import athirudramLefImg from '../assets/img/athirudram-leftside-image-1.png';
import donations from '../assets/img/donations-qr-code.png';
import footerBanner1 from '../assets/img/atirudram-footer-banner-1.png';
import footerBanner2 from '../assets/img/atirudram-footer-banner-2.png';
import DonationAccountInfo from "../component/donationAccount";
import DonationForm from '../component/donationForm';
export default class Athirudram2022 extends React.Component {
  constructor() {
    super();
    this.state = {leftSection:{},
                  leftSectionAccount:[],
                  athirudramSection:[],
                  footerSection:[]};
  }
  componentDidMount() {
    
    fetch('https://trust.sharavisual.com/data/athirudram2022.json')
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      this.setState({leftSection:data,
                      leftSectionAccount:data.leftSectionAccount,
                      athirudramSection:data.athirudramContent,
                      footerSection:data.footerImg})
      console.log('Fetched data:', data);
      // You can now use the data in your component state
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    }); 
  }    
  render() {
    return (
      <>
      <div class="container-fluid p-0 mb-5">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
          <img className="img-fluid w-100 w-sm-100 w-md-100" src={'https://trust.sharavisual.com/assets/athirudram/athirudram2022.png'} alt="Group" />
      </div>
    </div>
    
<div class="container">

<div class="row justify-content-around gy-4">
  <div class="col-lg-10 d-flex flex-column justify-content-center">
    <div className='row'>
      {/* Desktop view */}
      <div className='col-12 col-12 col-lg-3 d-none d-sm-block d-md-block d-lg-block d-xl-block'>
      <img src={this.state.leftSection!=undefined && this.state.leftSection.leftsectionImg} className='flex-shrink-0 me-3 mb-3 img-fluid' style={{width:"100%"}}/>
      <p className='text-justify-normal'>{this.state.leftSection!=undefined && this.state.leftSection.leftSectionDescription}</p>
        <DonationAccountInfo {...this.state}/>
      
        {/* Donation Form Component here */}
        <DonationForm {...this.props}/>
      </div>

      <div className='col-12 col-lg-9 athirudram'>
          {
            this.state.athirudramSection.length>0 && this.state.athirudramSection.map((item,index)=>{
          return(
          <>
          <div className='col-sm-12 col-lg-12'>
           <h4><a href="" class="text-decoration-none  text-secondry">{item.heading}</a></h4>
           {item.subTitle!="" && <h6>{item.subTitle}</h6> }          
           <p dangerouslySetInnerHTML={{__html: item.description}}></p>
           </div>
           {item.readmoreUrl!="" && <p className='w-100 text-end'>
              <span className='w-100'>
                <a href={item.readmoreUrl}>Readmore..</a>
              </span>
            </p>}
          </>)
         })
         }               
        <div className='float-clear'></div>
        <div className='row'>
        {
         this.state.footerSection.length>0 && this.state.footerSection.map((item,index)=>{
          return(
          <>
          <div className='col-6 col-lg-6'>
            {item.link !="" ?
              <a href={item.link} target='_blank' data-interception="off">
                <img src={item.img} className='flex-shrink-0 me-3 mb-3 img-fluid' />
              </a> :  <img src={item.img} className='flex-shrink-0 me-3 mb-3 img-fluid' />
              
              }
            
          </div>
          </>)
          })
          }   
        </div>
         
      </div>
      
      {/* Mobile view */}
      <div className='col-12 col-sm-12 col-lg-3 d-block d-sm-none d-lg-none'>
      <img src={this.state.leftSection!=undefined && this.state.leftSection.leftsectionImg} className='flex-shrink-0 me-3 mb-3 img-fluid' style={{width:"100%"}}/>
      <p className='text-justify-normal'>{this.state.leftSection!=undefined && this.state.leftSection.leftSectionDescription}</p>
        <DonationAccountInfo {...this.state}/>
      
        {/* Donation Form Component here */}
        <DonationForm {...this.props}/>
      </div>
      
    </div>
  </div>
</div>
</div>
      </>
    );
  }
}