// import React, { Component } from "react";
// import axios from "axios";
// import { FilePond } from "react-filepond";
// import "filepond/dist/filepond.min.css";

// class SponsorsGallery extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             files: [],  // To store selected files
//             name: "",   // Store name input
//             year: "",   // Store year input
//         };
//     }

//     handleImageChange = (files) => {
//         this.setState({ files });  // Store selected files
//     };

//     handleInputChange = (e) => {
//         const { name, value } = e.target;
//         this.setState({ [name]: value });  // Update name and year in state
//     };

//     handleUpload = async () => {
//         const { files, name, year } = this.state;

//         if (files.length === 0 || !name || !year) {
//             alert("Please provide all the details (name, year, and image).");
//             return;
//         }

//         const formData = new FormData();
//         formData.append("image", files[0].file);  // Append the selected file
//         formData.append("name", name);  // Append name
//         formData.append("year", year);  // Append year

//         try {
//             const response = await axios.post(
//                 "https://trust.sharavisual.com/api/sponsorgallery.php",
//                 formData,
//                 {
//                     headers: {
//                         "Content-Type": "multipart/form-data",
//                     },
//                 }
//             );
//             alert(response.data.message);  // Alert the server response
//         } catch (error) {
//             console.error("Error uploading image:", error);
//             alert("Image upload failed.");
//         }
//     };

//     render() {
//         return (
//             <div>
//                 <form class="forms-sample">
//                     <div class="form-group">
//                         <label for="activitieName">Name <span className='text-danger'>*</span></label>
//                         <input
//                             type="text"
//                             name="name"
//                             value={this.state.name}
//                             onChange={this.handleInputChange}
//                             placeholder="Enter Sponsor Name"
//                             class="form-control w-50"
//                         />
//                     </div>
//                     <div class="form-group">
//                         <label for="date">Year</label>
//                         <input
//                             type="text"
//                             name="year"
//                             value={this.state.year}
//                             onChange={this.handleInputChange}
//                             placeholder="Enter Year"
//                             class="form-control w-50"
//                         />
//                     </div>


//                 </form>
//                 <label>Image</label>
//                 <FilePond
//                     files={this.state.files}
//                     allowMultiple={false}
//                     onupdatefiles={this.handleImageChange}
//                     labelIdle='Drag & Drop your image or <span class="filepond--label-action">Browse</span>'
//                     className="w-50"
//                 />


//                 <button onClick={this.handleUpload}>Upload</button>
//             </div>
//         );
//     }
// }

// export default SponsorsGallery;



import React, { Component } from "react";
import axios from "axios";
import { FilePond } from "react-filepond";
import DataTable from "react-data-table-component";
import "filepond/dist/filepond.min.css";

class SponsorsGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],  // To store selected files
            name: "",   // Store name input
            year: "",   // Store year input
            uploadedImages: [],
            sponsorsList: []  // Store fetched sponsor data
        };
    }

    // Fetch the uploaded images (name, year, image_path) from the backend
    getSponsorsList = () => {
        fetch('https://trust.sharavisual.com/api/getsponsor.php')  // Your API endpoint
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            if (Array.isArray(data)) {
              this.setState({ sponsorsList: data });
              console.log('Fetched sponsor data:', data);
            } else {
              console.error('Error from API:', data.message);
            }
          })
          .catch(error => {
            console.error('Error fetching sponsor data:', error);
          });
      }

    // Handle image file selection and update state
    handleImageChange = (files) => {
        this.setState({ files });
    };

    // Handle form input change (name, year)
    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    // Handle upload to backend
    handleUpload = async () => {
      debugger;
        const { files, name, year } = this.state;

        if (files.length === 0 || !name || !year) {
            alert("Please provide all the details (name, year, and image).");
            return;
        }

        const formData = new FormData();
         // Append each file to FormData
        files.forEach((fileObj, index) => {
          debugger;
          formData.append(`images[]`, fileObj.file); // Use `images[]` to handle multiple files
          // Add the 'name' field
        });
        formData.append("name", name);
        formData.append("year", year);
debugger;
        axios.post("https://trust.sharavisual.com/api/sponsorgallery.php",
          formData,
          {
              headers: {
                  "Content-Type": "multipart/form-data",
              },
          }
      ).then((response) => {
        debugger;
        console.log(response.data);
        
        if (response.data.status) {
             this.setState({
                name:'',
                year:'',
                files: [],
                },()=>{
                  this.getSponsorsList();;
                  //this.getActivitiesList();
                  alert(response.data.message); 
                })
                
        } else {
            alert('Failed to add contact: ' + response.data.message);
        }
    })
    .catch((error) => {
        console.error("Error:", error);
    });

      
    };
    handleDelete = (id) => {
        // Send delete request to the backend
        fetch(`https://trust.sharavisual.com/api/sponsordelete.php?id=${id}`, {
          method: 'DELETE', // Use DELETE method
        })
          .then(response => response.json())
          .then(data => {
            if (data.status) {
              // On success, remove the deleted sponsor from the state
              this.setState(prevState => ({
                sponsorsList: prevState.sponsorsList.filter(sponsor => sponsor.id !== id),
              }));
              alert('Sponsor deleted successfully');
            } else {
              alert('Error deleting sponsor');
            }
          })
          .catch(error => {
            console.error('Error deleting sponsor:', error);
            alert('Failed to delete sponsor');
          });
      }

    componentDidMount() {
        this.getSponsorsList();
      }

    render() {
        const { uploadedImages } = this.state;

        // Define the columns for the DataTable
        const columns = [
            {
              name: 'Sponsor Name',
              selector: (row) => row.name,  // Row data for sponsor name
              sortable: true,
            },
            {
              name: 'Year',
              selector: (row) => row.year,  // Row data for sponsor year
              sortable: true,
            },
            {
                name: 'Image',
                selector: (row) => row.image_path,  // Row data for image path
                cell: (row) => {
                  debugger;
                  return(
                  
                    <img src={`https://trust.sharavisual.com${row.image_path}`} alt={row.name} width="200px"></img> 
                  
                );
              }
              },
              {
                name: 'Actions',
                button: true,
                cell: (row) => (
                  <button 
                    onClick={() => this.handleDelete(row.id)}  // Delete on button click
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                ),
              },
          ];

        return (
            <div>
                <form className="forms-sample">
                    <div className="form-group">
                        <label>Name <span className="text-danger">*</span></label>
                        <input
                            type="text"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleInputChange}
                            placeholder="Enter Sponsor Name"
                            className="form-control w-50"
                        />
                    </div>
                    <div className="form-group">
                        <label>Year <span className="text-danger">*</span></label>
                        <input
                            type="text"
                            name="year"
                            value={this.state.year}
                            onChange={this.handleInputChange}
                            placeholder="Enter Year"
                            className="form-control w-50"
                        />
                    </div>
                </form>

                <label>Image <span className="text-danger">*</span></label>
                <FilePond
                  files={this.state.files}
                  allowMultiple={true} // Allow multiple file uploads
                  onupdatefiles={(fileItems) => {
                    this.setState({ files: fileItems });
                  }}
                  labelIdle='Drag & Drop your images or <span class="filepond--label-action">Browse</span>'
                  className="w-50"
                />


                <button className="btn btn-primary" onClick={this.handleUpload}>Upload</button>

                <>
        <h4  className="mt-5">Sponsors Gallery</h4>
        <DataTable
          columns={columns}
          data={this.state.sponsorsList}  // Pass the sponsor data here
          pagination
          highlightOnHover
          striped
        />
      </>
            </div>
        );
    }
}

export default SponsorsGallery;

