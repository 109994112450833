import React from 'react';
import Privilage from '../component/privilage';
import Sample from '../component/underPrivilegeGallery';

export default class UnderprivilegeSection extends React.Component {
  constructor() {
    super();
    this.state = {color: "red"};
  }
  render() {
    return (
         <>
         <Privilage/>
         <Sample/>
         </>
    );
  }
}
