import React from 'react';
import Involved from '../component/involved';
export default class GetInvolved extends React.Component {
  constructor() {
    super();
    this.state = {color: "red"};
  }
  render() {
    return  (
      <>
      <Involved/>
      </>
    )
  
}
}