import React from 'react';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
export default class DonationTable extends React.Component {
  constructor() {
    super();
    this.state = {DonationList:[]};
  }   
   getDonationList=()=>{
   fetch('https://trust.sharavisual.com/api/getdonation.php')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data.status) {
          this.setState({ DonationList: data.data });
          console.log('Fetched data:', data);
        } else {
          console.error('Error from API:', data.message);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
     
    } 
    
    exportToExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(this.state.DonationList);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'DonationList');
  
      // Trigger the download
      XLSX.writeFile(workbook, 'DonationList.xlsx');
    };
  
    componentDidMount() {
          
      this.getDonationList();  
    }
  render() {
    const columns = [
       
        {
          name: 'Name',
          selector: (row) => row.name,
          sortable: true,
        },
        {
          name: 'Age',
          selector: (row) => row.age,
          sortable: true,
        },
        {
            name: 'Address',
            selector: (row) => row.address,
            sortable: true,
          },
          {
            name: 'zipcode',
            selector: (row) => row.zipcode,
            sortable: true,
          },
          
          {
            name: 'phone',
            selector: (row) => row.phone,
            sortable: true,
          },
         {
            name: 'Email',
            selector: (row) => row.email,
            sortable: true,
          },
         
          {
            name: 'PAN No',
            selector: (row) => row.PANno,
            sortable: true,
          },

          {
            name: 'TrnxNo',
            selector: (row) => row.trnxNo,
            sortable: true,
          },
      ];
    return (
         <>
                 <div style={{ marginBottom: '10px' }}>
          <button className='btn btn-primary' onClick={this.exportToExcel} style={{ padding: '10px', cursor: 'pointer' }}>
            Export to Excel
          </button>
        </div>
         <DataTable
          columns={columns}
          data={this.state.DonationList}
          pagination
        />
         </>
    );
  }
}