import React, { Component } from 'react';

class DonationAccountInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentDidMount() {

  }
  render() {


    return (
      <>
        {this.props.leftSectionAccount.length > 0 && this.props.leftSectionAccount.map((item, index) => {
          return (
            <>
              <p className='mb-0 text-justify-normal'>
                <b>Account Name:</b><br /> {item.accontName},<br />
                <b>Bank:</b> {item.bank}<br />
                <b>Branch:</b> {item.branch}<br />
                <b>Account Type: {item.accontType}</b><br />
                <b>Account Number: <span className='fw-1 fs-4' style={{ wordBreak: "break-word" }}>{item.accountNumber}</span></b>
              </p>
              <p className='fw-1 fs-5 mb-0 text-justify-normal'>IFSC CODE: {item.ifscCode}</p>
              <p className='fw-1 fs-5 text-justify-normal'>UPI ID: {item.upiId}</p>
              <div className="text-sm-start text-center">
                <h5 className="mb-3 text-center">Donations</h5>
                <div className="d-flex justify-content-center justify-content-sm-center">
                  <img
                    src={item.qurCode}
                    className="flex-shrink-0 me-3 mb-3 img-fluid"
                    style={{ width: "180px" }}
                    alt="QR Code"
                  />
                </div>
              </div>
            </>)
        })}
        <p className='text-justify-normal'><small>Kindly fill in your details below your contribution details and submit</small></p>
      </>
    );
  }
}

export default DonationAccountInfo;
