  import React, { Component } from "react";
  import axios from "axios";
  import { FilePond } from "react-filepond";
  import DataTable from "react-data-table-component";
  import "filepond/dist/filepond.min.css";

  class CoSponsors extends Component {
      constructor(props) {
          super(props);
          this.state = {
              files: [],  // To store selected files
              name: "",   // Store name input           
              uploadedImages: [],
              sponsorsList: []  // Store fetched sponsor data
          };
      }

      // Fetch the uploaded images (name, year, image_path) from the backend
      getCoSponsorsList = () => {
          fetch('https://trust.sharavisual.com/api/getCoSponsor.php')  // Your API endpoint
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then(data => {
              if (Array.isArray(data)) {
                this.setState({ sponsorsList: data });
                console.log('Fetched sponsor data:', data);
              } else {
                console.error('Error from API:', data.message);
              }
            })
            .catch(error => {
              console.error('Error fetching sponsor data:', error);
            });
        }

      // Handle image file selection and update state
      handleImageChange = (files) => {
          this.setState({ files });
      };

      // Handle form input change (name, year)
      handleInputChange = (e) => {
          const { name, value } = e.target;
          this.setState({ [name]: value });
      };

      handleUpload = async () => {
        const { files } = this.state;
      
        if (files.length === 0) {
          alert("Please select at least one file to upload.");
          return;
        }
      
        const formData = new FormData();
        
        // Append each file to FormData
        files.forEach((fileObj, index) => {
          debugger;
          formData.append(`images[]`, fileObj.file); // Use `images[]` to handle multiple files
          // Add the 'name' field
        });
        formData.append("name", 'CoSponsor');
        
      
        axios
          .post("https://trust.sharavisual.com/api/coSponsor.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.status) {
              this.setState(
                {
                  name: '',
                  files: [],
                },
                () => {
                  this.getCoSponsorsList();
                  alert(response.data.message);
                }
              );
            } else {
              alert("Failed to upload files: " + response.data.message);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      };

      handleDelete = (id) => {
          // Send delete request to the backend
          fetch(`https://trust.sharavisual.com/api/coSponsorDelete.php?id=${id}`, {
            method: 'DELETE', // Use DELETE method
          })
            .then(response => response.json())
            .then(data => {
              if (data.status) {
                // On success, remove the deleted sponsor from the state
                this.setState(prevState => ({
                  sponsorsList: prevState.sponsorsList.filter(sponsor => sponsor.id !== id),
                }));
                alert('Sponsor deleted successfully');
              } else {
                alert('Error deleting sponsor');
              }
            })
            .catch(error => {
              console.error('Error deleting sponsor:', error);
              alert('Failed to delete sponsor');
            });
        }

      componentDidMount() {
          this.getCoSponsorsList();
        }

      render() {
          const { uploadedImages } = this.state;

          // Define the columns for the DataTable
          const columns = [
              {
                name: 'Co Sponsors',
                selector: (row) => row.name,  // Row data for sponsor name
                sortable: true,
              },
              
              {
                  name: 'Image',
                  selector: (row) => row.image_path,  // Row data for image path
                  cell: (row) => {
                    debugger;
                    return(
                    
                      <img src={`https://trust.sharavisual.com${row.image_path}`} alt={row.name} width="200px"></img> 
                    
                  );
                }
                },
                {
                  name: 'Actions',
                  button: true,
                  cell: (row) => (
                    <button 
                      onClick={() => this.handleDelete(row.id)}  // Delete on button click
                      className="btn btn-danger"
                    >
                      Delete
                    </button>
                  ),
                },
            ];

          return (
              <div>
                  

                  <label>Image</label>
                  <FilePond
          files={this.state.files}
          allowMultiple={true} // Allow multiple file uploads
          onupdatefiles={(fileItems) => {
            this.setState({ files: fileItems });
          }}
          labelIdle='Drag & Drop your images or <span class="filepond--label-action">Browse</span>'
          className="w-50"
        />

                  <button className="btn btn-primary" onClick={this.handleUpload}>Upload</button>

                  <>
          <h4  className="mt-5">Sponsors Gallery</h4>
          <DataTable
            columns={columns}
            data={this.state.sponsorsList}  // Pass the sponsor data here
            pagination
            highlightOnHover
            striped
          />
        </>
              </div>
          );
      }
  }

  export default CoSponsors;

