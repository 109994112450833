import React from 'react';
import Activities from '../component/activities';
export default class ActivitieSection extends React.Component {
  constructor() {
    super();
    this.state = {color: "red"};
  }
  render() {
    return (
      <Activities/>
    );
  }
}