import React from 'react';
import Regularsponsors from '../component/regularsponsor';
import Partner from '../component/partner';
import Trust from '../component/Trusties';
import Gallerys from '../component/Gallery';
import Reports from '../component/report';
export default class GalleryAndReprts extends React.Component {
  constructor() {
    super();
    this.state = {color: "red"};
  }
  render() {
    return (
         <>        
         <Gallerys/>
         <Reports/>
         </>
    );
  }
}
