import React from 'react';
import Trust from '../component/Trusties';

export default class Trustees extends React.Component {
  constructor() {
    super();
    this.state = {color: "red"};
  }
  render() {
    return (
         <>
         <Trust {...this.props}/>
         </>
    );
  }
}
