import React, { Component } from 'react';
import $ from "jquery";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import partner1 from '../assets/img/partner1.png';
import partner2 from '../assets/img/partner2.png';
import partner3 from '../assets/img/partner3.png';
import partner4 from '../assets/img/partner4.png';
import partner5 from '../assets/img/partner5.png';
import partner6 from '../assets/img/partner6.png';


class Partner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      CoSponsorsList:[]
    };
    this.images = [partner1, partner2, partner3, partner4, partner5, partner6];
  }
   
  getCoSponsorsList = () => {
    fetch('https://trust.sharavisual.com/api/getCoSponsor.php')  // Your API endpoint
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data)) {
          this.setState({ CoSponsorsList: data });
          console.log('Fetched sponsor data:', data);
        } else {
          console.error('Error from API:', data.message);
        }
      })
      .catch(error => {
        console.error('Error fetching sponsor data:', error);
      });
  }


  componentDidMount() {
    this.getCoSponsorsList();
    //this.interval = setInterval(this.nextImage, 2000); // Change image every 2 seconds
  
  }

  componentWillUnmount() {
    //clearInterval(this.interval);
  }

  nextImage = () => {
    this.setState((prevState) => ({
      currentIndex: (prevState.currentIndex + 1) % this.images.length,
    }));
  };

  goToImage = (index) => {
    this.setState({ currentIndex: index });
  };

  render() {
    const { currentIndex } = this.state;
    //const imagesToShow = [...this.images, ...this.images]; 
    //const dotIndex = Math.floor(currentIndex / 2) % 3;
    const options = {
      loop: true,
      margin: 2,
      nav: false,
      autoplay: true, // Enable autoplay
      autoplayTimeout: 3000, // Time between slides (3 seconds)
      autoplayHoverPause: true, // Pause on hover
      smartSpeed: 1000, // Speed of transitions
      dots:false,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        },
      },
    };
    return (
          // <Container>
          //      <div className='row'>
          //         {this.state.CoSponsorsList.length>0 ? this.state.CoSponsorsList.map((item,index)=>{
          //           return(
          //             <div className='col-6 col-lg-3'>
          //             <img
          //              key={index}
          //              src={`https://trust.sharavisual.com${item.image_path}`}
          //              alt={`Partner ${index + 1}`}
          //              className="item img-fluid"
          //             ></img>
          //             </div>
          //           )
                    
          //         })
                  
          //         : <p>--No Sponsor--</p>}
          //      </div>
          // </Container>
        
//       <div className="slider wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
//        {this.state.CoSponsorsList.length >0 ? <Container className='carousel mt-5 p-3'>
            
//           <div className="image-slider">
//             <div
//               className="image-slider-inner"
//               style={{
//                 transform: `translateX(-${(currentIndex * 100) }%)`,
//               }}
//             >
//               {this.state.CoSponsorsList.map((image, index) => {
// debugger;
//                 return(
//                 <img
//                   key={index}
//                   src={`https://trust.sharavisual.com${image.image_path}`}
//                   alt={`Partner ${index + 1}`}
//                   className="item"
//                 />
//               )})}
//             </div>
//           </div>
//           <div className="dots-container">
//             {[0, 1, 2.3,4].map((index) => (
//               <div
//                 key={index}
//                 className={`dot ${dotIndex === index ? 'active' : ''}`}
//                 onClick={() => this.goToImage(index * 2)}
//               />
//             ))}
//           </div>
//         </Container> : <p>--No Sponsor--</p> }
//       </div>

<div class="container-fluid wow fadeInUp" data-wow-delay="0.1s">
        <div class="container mb-4 mt-4">
            <div class="bg-white">
           {
           this.state.CoSponsorsList.length>0 && <OwlCarousel className="owl-theme" {...options}>
           
                    {this.state.CoSponsorsList.map((image, index) => {
                            return(
                              <div className="item_1">
                                  <img
                                    key={index}
                                    src={`https://trust.sharavisual.com${image.image_path}`}
                                    alt={`Partner ${index + 1}`}
                                   
                                  />
                                    </div>
                        )})}
                      
                   </OwlCarousel>
    }
                    </div>
                    </div>
                    </div>

    );
  }
}

export default Partner;
