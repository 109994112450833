import React from 'react';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';

export default class GetInvolvedTable extends React.Component {
  constructor() {
    super();
    this.state = { GetInvolvedList: [] };
  }

  getInvolvedList = () => {
    fetch('https://trust.sharavisual.com/api/getInvolved.php')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.status) {
          this.setState({ GetInvolvedList: data.data });
          console.log('Fetched data:', data);
        } else {
          console.error('Error from API:', data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  componentDidMount() {
    this.getInvolvedList();
  }

  exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(this.state.GetInvolvedList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'GetInvolvedData');

    // Trigger the download
    XLSX.writeFile(workbook, 'GetInvolvedData.xlsx');
  };

  render() {
    const {GetInvolvedList} = this.state
    const columns = [
      {
        name: 'sectionName',
        selector: (row) => row.sectionName,
        sortable: true,
      },
      {
        name: 'Category',
        selector: (row) => row.SponsorCategory,
        sortable: true,
      },
      {
        name: 'vaideekam',
        selector: (row) => row.vaideekam,
        sortable: true,
      },
      
      {
        name: 'Name',
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: 'Age',
        selector: (row) => row.age,
        sortable: true,
      },
      {
        name: 'Phone',
        selector: (row) => row.phone,
        sortable: true,
      },
      {
        name: 'Email',
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: 'Address 1',
        selector: (row) => row.address1,
        sortable: true,
      },
      {
          name: 'Address 2',
          selector: (row) => row.address2,
          sortable: true,
        },
        {
          name: 'City',
          selector: (row) => row.city,
          sortable: true,
        },

        {
          name: 'state',
          selector: (row) => row.state,
          sortable: true,
        },
        {
          name: 'Pin',
          selector: (row) => row.zipcode,
          sortable: true,
        },
        {
          name: 'PAN No',
          selector: (row) => row.PANno,
          sortable: true,
        },

        {
          name: 'Amount',
          selector: (row) => row.amount,
          sortable: true,
        },
        {
          name: 'Transaction',
          selector: (row) => row.trnxNo,
          sortable: true,
        }
    ];
    // const tableData = {
    //   columns,
    //   data:GetInvolvedList
    // };
   debugger;
    return (
      <>
      <div style={{ marginBottom: '10px' }}>
          <button className='btn btn-primary' onClick={this.exportToExcel} style={{ padding: '10px', cursor: 'pointer' }}>
            Export to Excel
          </button>
        </div>
      {GetInvolvedList && GetInvolvedList.length > 0 ? (
         
              <DataTable
                  columns={columns}
                  data={this.state.GetInvolvedList}
                  pagination
              />
        
      ) : (
          <p>Loading data...</p>
      )}
  </>
    );
  }
}
