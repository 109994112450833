// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './animate.min.css';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <Router>
// <App />
// </Router>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './animate.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

class MetaTags extends Component {
  componentDidMount() {
    debugger;
    this.updateMetaTags();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.title !== this.props.title ||
      prevProps.description !== this.props.description ||
      prevProps.keywords !== this.props.keywords
    ) {
      this.updateMetaTags();
    }
  }

  updateMetaTags=()=> {
    debugger;
    const { title, description, keywords } = this.props;

    document.title = title || 'Default Title';

    let metaDescription = document.querySelector('meta[name="description"]');
    let metaKeywords = document.querySelector('meta[name="keywords"]');

    if (description) {
      if (metaDescription) {
        metaDescription.content = description;
      } else {
        metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        metaDescription.content = description;
        document.head.appendChild(metaDescription);
      }
    }

    if (keywords) {
      if (metaKeywords) {
        metaKeywords.content = keywords;
      } else {
        metaKeywords = document.createElement('meta');
        metaKeywords.name = 'keywords';
        metaKeywords.content = keywords;
        document.head.appendChild(metaKeywords);
      }
    }
  }

  render() {
    return null;
  }
}

class Root extends Component {
  state = {
    metaData: { title: '', description: '', keywords: '' },
    analytics:''
  };

  componentDidMount() {
    debugger;
    this.fetchMetaData();
   
  }

   fetchMetaData=async()=> {
    debugger;
    try {
      const response = await fetch('https://trust.sharavisual.com/api/getMeta.php');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      debugger;
      if (data.status) {
        if(data.data.length >0){
          this.setState({
            metaData: {
              title: data.data[0].meta || 'Default Title',
              description: data.data[0].description || 'Default Description',
              keywords: data.data[0].keywords || 'Default Keywords',
            },
            analytics:data.data[0].analytics
          },()=>{
            this.addAnalytics(this.state.analytics);
          });
        }       
        console.log('Fetched data:', data);
      } else {
        console.error('Error from API:', data.message);
      }
    } catch (error) {
      console.error('Error fetching meta data:', error);
    }
  }

  addAnalytics=(analytics)=> {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${analytics}`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'GA_TRACKING_ID'); // Replace with your Google Analytics ID
    };
  }

  render() {
    const { metaData } = this.state;

    return (
      <>
        <MetaTags 
          title={metaData.title} 
          description={metaData.description} 
          keywords={metaData.keywords} 
        />
        <Router>
          <App />
        </Router>
      </>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);

// If you want to start measuring performance in your app
reportWebVitals();
