import React from 'react';
import axios from "axios";
import { withRouter } from 'react-router-dom';
import '../../assets/admin/css/login.css';
import '../../assets/admin/css/admin.scss';
import GetActivitiesTable from '../admin/getActivitiesTable';
class AddActivitie extends React.Component {
  constructor() {
    super();
    this.state = {
      refershTable:false,
      activitieName:"", 
      date:new Date(), 
      time:"",
       place:"",
       isvilated:false,
       isdisableBtn:true,
       ActivitiesList:[]};
  }

     // Handle input change
     handleInputChange = (e) => {
      
      const { name, value } = e.target;
      this.setState({ [name]: value },()=>{
           if(this.state.isvilated){
              this.validation();
           }
          
        });
  };  
  handleSubmit = (e) => {
      e.preventDefault();
      if(this.validation()){
                 
          const { activitieName, date, time, place } = this.state;
  
          // Create a FormData object to hold the data as form-data (multipart/form-data)
          const formData = new FormData();
          formData.append('activitieName', activitieName);
          formData.append('date', date);
          formData.append('time', time);
          formData.append('place', place);
        
      
          axios.post('https://trust.sharavisual.com/api/activities.php', formData, {
              headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
              console.log(response.data);
              
              if (response.data.status) {
                   this.setState({activitieName:'',
                      date:new Date(),
                      time:'',
                      place: '',                     
                      isdisableBtn:true,isvilated:false,
                      refershTable:true},()=>{
                           
                        //this.getActivitiesList();
                        this.fetchItems();
                      })
                  //alert(response.message);
              } else {
                  alert('Failed to add contact: ' + response.data.message);
              }
          })
          .catch((error) => {
              console.error("Error:", error);
          });

      }else{
        alert('Please fill Activitie Name,Date,Time and Place');
      }
     
  };
  
   validation=()=>{
      
      let flag =true;
     
      
      if(this.state.activitieName==''){
          flag=false;
      }
      if(this.state.time==''){
          flag=false;
      }
      if(this.state.place==''){
          flag=false;
      }
      
     
      this.setState({isdisableBtn: (flag == true ? true : false),isvilated:true})
      return flag
   }  
   
   getActivitiesList=()=>{
    
    fetch('https://trust.sharavisual.com/api/getActivities.php')
    .then(response => { 
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      if (data.status) {
        this.setState({ ActivitiesList: data.data });
        console.log('Fetched data:', data);
      } else {
        console.error('Error from API:', data.message);
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
   
    } 


    fetchItems = () => {
      
      axios.get('https://trust.sharavisual.com/api/activities.php')
          .then(response => {
              this.setState({ ActivitiesList: response.data.data });
          })
          .catch(error => console.error("There was an error fetching the items!", error));
  };

 // Delete an item
 deleteItem = (id) => {
  axios.delete(`https://trust.sharavisual.com/api/activities.php?id=${id}`)
      .then(() => {
          this.fetchItems();
      })
      .catch(error => console.error("There was an error deleting the item!", error));
};

// Edit item
editItem = (id) => {
  axios.get(`https://trust.sharavisual.com/api/activities.php?id=${id}`)
          .then(response =>                
            {
                if(response.data.status){
                  let obj = response.data.data[0]
                  this.setState({
                    editId:obj.id,
                    activitieName:obj.activitieName,
                    date:obj.date,
                    time:obj.time,
                    place:obj.place
                    });
                }
             
          })
          .catch(error => console.error("There was an error fetching the items!", error));
  
};

// Update item
updateItem = () => {
  debugger;
  const { editId, activitieName, date, time, place } = this.state;
  axios
    .put('https://trust.sharavisual.com/api/activities.php', { 
      id: editId, // Include 'id' in the body
      activitieName: activitieName,
      date: date,
      time: time,
      place: place
    })
    .then(response => {
      debugger;
      if (response.status) {
        this.setState({
          editId: '',
          activitieName: '',
          date: new Date(),
          time: '',
          place: ''
        });
        this.fetchItems();
      }
    })
    .catch(error => console.error("There was an error updating the item!", error));
};




componentDidMount(){
  //this.getActivitiesList()
  this.fetchItems();
}

  render() {
    return (
         <>
    
          <div class="row">
            <div class="col-6 col-lg-6 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Activities</h4>
                 
                  <form class="forms-sample">
                    <div class="form-group">
                      <label for="activitieName">Activitie Name <span className='text-danger'>*</span></label>
                      <input type="text" class="form-control" id="activitieName" name="activitieName" value={this.state.activitieName} onChange={this.handleInputChange} placeholder="Name"></input>
                    </div>
                    <div class="form-group">
                      <label for="date">Date</label>
                      <input type="Date" class="form-control" id="date" name="date" value={this.state.date} onChange={this.handleInputChange} placeholder="Date"></input>
                    </div>
                    <div class="form-group">
                      <label for="time">Time</label>
                      <input type="text" class="form-control" id="time" name="time" value={this.state.time} onChange={this.handleInputChange} placeholder="Time"></input>
                    </div>
                    <div class="form-group w-100">
                      <label for="place">Place</label>
                      <textarea class="form-control" id="place" name="place" value={this.state.place} onChange={this.handleInputChange} rows="4" style={{minHeight: "5rem"}}></textarea>
                    </div>
                    <button type="submit" class="btn btn-primary me-2 mt-2" onClick={this.state.editId ? this.updateItem : this.handleSubmit}>
                    {this.state.editId ? "Update" : "Add"}</button>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-6 col-lg-6 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Activities</h4>
                 

                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Place</th>
                          <th>Edit</th>
                          <th>Delete</th>                          
                        </tr>
                      </thead>
                      <tbody>
                         {this.state.ActivitiesList.length>0 && this.state.ActivitiesList.map((item,index)=>{
                          return(
                          <tr>
                          <td>{item.activitieName}</td>
                          <td>{item.date}</td>
                          <td>{item.time}</td>
                          <td>{item.place}</td>
                          <td><button className='btn btn-primary' onClick={()=>this.editItem(item.id)}>Edit</button></td>
                          <td><button className='btn btn-primary' onClick={()=>this.deleteItem(item.id)}>Delete</button></td>
                          </tr>
                          )
                         })}
                        
                       
                      </tbody>
                    </table>
                  </div>
                 
               
                </div>
              </div>
            </div>
          </div>
       
         </>
    );
  }
}
export default AddActivitie;