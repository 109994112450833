import { data } from 'jquery';
import React, { Component } from 'react';
import axios from "axios";
class Reports extends Component {
    constructor(props) {
        super(props);
        this.state={
          filterReports: [],
          reports:[],
            currentYear:new Date().getFullYear()
        }
      }//End Constructor
    getDateDay =(date)=>{
        let inputDate = new Date(date);

// Options for formatting the date
let options = { day: '2-digit', month: 'short' };

// Format the date
let formattedDate = inputDate.toLocaleDateString('en-US', options);

// Get the day of the week
let dayOfWeek = inputDate.toLocaleDateString('en-US', { weekday: 'long' });

// Combine the formatted date and day of the week
 
let output = `${formattedDate}, ${dayOfWeek}`;

return output
    }
    fetchItems = () => {
      
      axios.get('https://trust.sharavisual.com/api/getAnnualReport.php')
          .then(response => {
            debugger;
              this.setState({ reports: response.data },()=>{
                 if(this.state.reports.length > 0 ){
                  debugger;
                  this.filterEvents();
                 }
                
              });
          })
          .catch(error => console.error("There was an error fetching the items!", error));
  };
componentDidMount(){
 this.fetchItems();
}

filterEvents = () => {
  debugger;
  const { reports } = this.state;
  const currentYear = new Date().getFullYear();
  debugger;
  const groupedEvents = reports.reduce((acc, event) => {
    const eventYear = event.year;
    if (!acc[eventYear]) {
      acc[eventYear] = [];
    }
    acc[eventYear].push(event);
    return acc;
  }, {});

  const sortedYears = Object.keys(groupedEvents)
    .map(Number)
    .filter((year) => year <= currentYear)
    .sort((a, b) => a - b);

  const filterReports = sortedYears.map((year) => ({
    year,
    events: groupedEvents[year]
  }));

  this.setState({ filterReports:filterReports });
};
  render() {
      

    return (
      <div className="container">
        <div className="row py-5">
        <h2 style={{color:"#731414"}}>Sree Ramakrishna Bhajana Sabha Trust Annual Report</h2>
        {this.state.filterReports.length >0 ? this.state.filterReports.map((yearGroup) => (
            <div key={yearGroup.year}>
            <h5 style={{color:"#731414"}}>Annual Report {yearGroup.year}</h5>
            <div className="col-lg-10 border border-top-0 border-start-0 border-end-0 wow fadeInUp " data-wow-delay="0.1s">
              {yearGroup.events.map((event) => (
                <p key={event.id}> <a href={`https://trust.sharavisual.com${event.image_path}`} target='_blank'> <strong><i class="fas fa-file-pdf" style={{fontSize:"18px",marginRight:"1rem"}}></i>{event.name}</strong> </a></p>
              ))}
            </div>
          </div>
        )) : <p>--No Reprts--</p>}
          
        </div>
      </div>
    );
  
  }
}
export default Reports;
