import React, { Component } from "react";
import axios from 'axios'; // Import axios for API calls
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";
//import { images, CustomImage } from "./images";

class Sample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images:[],
      index: -1, // Tracks the index of the currently selected image
    };
  }

  // Method to handle image click and open the lightbox
  handleClick = (index) => {
    this.setState({ index });
  };

  // Method to close the lightbox
  handleClose = () => {
    this.setState({ index: -1 });
  };

  // Method to move to the previous image
  handleMovePrev = () => {
    const { index,images } = this.state;
    const prevIndex = (index + images.length - 1) % images.length;
    this.setState({ index: prevIndex });
  };

  // Method to move to the next image
  handleMoveNext = () => {
    const { index,images } = this.state;
    const nextIndex = (index + 1) % images.length;
    this.setState({ index: nextIndex });
  };
  // Function to fetch images from the API
  fetchImages = async () => {
    try {
      const response = await axios.get('https://trust.sharavisual.com/api/getunderprivelge.php');
      if (response.data.status) {
        // Assuming the API response has an array of images under 'data'
        this.setState({
            images: response.data.data.map(image => ({
              src: `https://trust.sharavisual.com/${image.image_path}`,
              width: 4, // Set default width ratio (adjust as needed)
              height: 3, // Set default height ratio (adjust as needed)
              alt: image.name,
              id: image.id,
            }))
          });
      } else {
        console.error('Error fetching images:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };
  // Fetch images when the component mounts
  componentDidMount() {
    this.fetchImages();
  }

  render() {
    debugger;
    const { index,images } = this.state;
    const currentImage = images[index];
    const nextIndex = (index + 1) % images.length;
    const nextImage = images[nextIndex] || currentImage;
    const prevIndex = (index + images.length - 1) % images.length;
    const prevImage = images[prevIndex] || currentImage;

    return (
      <div>
        {/* Gallery Grid */}
        <div className="container p-3">
        <h4 className="fs-3 mb">Gallery</h4>
        <Gallery
          images={images}
          onClick={this.handleClick}
          enableImageSelection={false}
        />

        {/* Lightbox for full-screen view */}
        {currentImage && (
          <Lightbox
            mainSrc={'https://trust.sharavisual.com/'+currentImage.src}
            imageTitle={'https://trust.sharavisual.com/' + currentImage.alt}
            mainSrcThumbnail={currentImage.src}
            nextSrc={'https://trust.sharavisual.com/'+nextImage.src}
            nextSrcThumbnail={nextImage.src}
            prevSrc={'https://trust.sharavisual.com/'+prevImage.src}
            prevSrcThumbnail={prevImage.src}
            onCloseRequest={this.handleClose}
            onMovePrevRequest={this.handleMovePrev}
            onMoveNextRequest={this.handleMoveNext}
          />
        )}
        </div>
      </div>
    );
  }
}

export default Sample;
