import React, { Component } from "react";
import axios from "axios";
import { FilePond } from "react-filepond";
import DataTable from "react-data-table-component";
import "filepond/dist/filepond.min.css";

class AddUnderprivilege extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],  // To store selected files
            name: "",   // Store name input           
            uploadedImages: [],
            underprivilegeList: []  // Store fetched sponsor data
        };
    }

   	  // Fetch the uploaded images from the database
       getUnderprivilegeList = () => {
        axios
          .get("https://trust.sharavisual.com/api/getunderprivelge.php") // Replace with your API endpoint
          .then((response) => {
          if (response.data.status) {
            this.setState({ underprivilegeList: response.data.data });
          } else {
            console.error("Error fetching data:", response.data.message);
          }
          })
          .catch((error) => {
          console.error("Error fetching data:", error);
          });
        };
    

    // Handle image file selection and update state
    handleImageChange = (files) => {
        this.setState({ files });
    };

    // Handle form input change (name, year)
    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleUpload = async () => {
      const { files } = this.state;
    
      if (files.length === 0) {
        alert("Please select at least one file to upload.");
        return;
      }
    
      const formData = new FormData();
      
      // Append each file to FormData
      files.forEach((fileObj, index) => {
        debugger;
        formData.append(`images[]`, fileObj.file); // Use `images[]` to handle multiple files
         // Add the 'name' field
      });
      formData.append("name", 'UnderPrivilege');
      
    
      axios
        .post("https://trust.sharavisual.com/api/Addunderprivelge.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.status) {
            this.setState(
              {
                name: '',
                files: [],
              },
              () => {
                this.getUnderprivilegeList();
                alert(response.data.message);
              }
            );
          } else {
            alert("Failed to upload files: " + response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    handleDelete = (id) => {
        // Send delete request to the backend
        fetch(`https://trust.sharavisual.com/api/deleteUnderprivilege.php?id=${id}`, {
          method: 'DELETE', // Use DELETE method
        })
          .then(response => response.json())
          .then(data => {
            if (data.status) {
              // On success, remove the deleted sponsor from the state
              this.setState(prevState => ({
                underprivilegeList: prevState.underprivilegeList.filter(item => item.id !== id),
              }));
              alert('Privilege gallery image deleted successfully');
            } else {
              alert('Error deleting');
            }
          })
          .catch(error => {
            console.error('Error deleting sponsor:', error);
            alert('Failed to delete Gallery');
          });
      }

    componentDidMount() {
        this.getUnderprivilegeList();
      }

    render() {
        const { uploadedImages } = this.state;

        // Define the columns for the DataTable
        const columns = [
            {
              name: 'Underprivilage',
              selector: (row) => row.name,  // Row data for sponsor name
              sortable: true,
            },
            
            {
              name: "Image",
              cell: (row) => (
                <img
                  src={`https://trust.sharavisual.com/${row.image_path}`} // Ensure this path is correct
                  width="100"
                />
              ),
            
              },
      
              {
                name: 'Actions',
                button: true,
                cell: (row) => (
                  <button
                  onClick={() => this.handleDelete(row.id)}
                  className="btn btn-danger"
                  >          
                    Delete
                  </button>
                ),
              },
          ];

        return (
            <div>
                
                <label>Image</label>
                <FilePond
        files={this.state.files}
        allowMultiple={true} // Allow multiple file uploads
        onupdatefiles={(fileItems) => {
          this.setState({ files: fileItems });
        }}
        labelIdle='Drag & Drop your images or <span class="filepond--label-action">Browse</span>'
        className="w-50"
      />

                <button className="btn btn-primary" onClick={this.handleUpload}>Upload</button>

                <>
        <h4  className="mt-5">Underprivilege Gallery</h4>
        <DataTable
          columns={columns}
          data={this.state.underprivilegeList}  // Pass the sponsor data here
          pagination
          highlightOnHover
          striped
        />
      </>
            </div>
        );
    }
}

export default AddUnderprivilege;

