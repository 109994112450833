import React, { Component } from 'react';

class Trust extends Component {
  constructor(props){
    super(props);
    this.state={
      profileDetails:[]
    }
  }
  componentDidMount(){
    fetch('https://trust.sharavisual.com/data/sponsor.json')
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      this.setState({profileDetails:data})
      console.log('Fetched data:', data);
      // You can now use the data in your component state
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    }); 
  }
  render() {
   

    return (
      <div className="container">
        <div className="row py-5">
            <h2 style={{color:"#731414"}}>Trustees</h2>
          {this.state.profileDetails.map((detail, index) => {
            return(
            <div key={index} className="col-lg-6 mt-3 wow fadeInUp" data-wow-delay="0.1s">
              <div className="">
                <h5><strong>{detail.title}</strong></h5>
                <h5 className='fw-light'>{detail.position}</h5>
                <p>{detail.content}</p>
              </div>
            </div>
          )})}
        </div>
      </div>
    );
  }
}

export default Trust;
