import React from 'react';
import logo from '../assets/img/logo.png';

export default class Header extends React.Component {
  constructor() {
    super();
    this.state = {color: "red"};
  }
  render() {
    return (
        <> 
     <div class="container-fluid bg-yellow p-0">
      <div class="row gx-0 d-none d-lg-flex">
        <div class="col-lg-7 px-5 text-start">  </div>
        <div class="col-lg-5 px-5 text-end">
          <div class="h-100 d-inline-flex align-items-center border-end p-2 me-2">
            <small class="fa fa-phone-alt me-2"></small>
            <small>9880111024 / 9854812545</small>
          </div>
          <div class="h-100 d-inline-flex align-items-center">            
            <small> 9945695670 / 8073748697</small>
          </div>
        
        </div>
      </div>
    </div>

    {/* <div className="container-fluid bg-yellow p-0">
        <div className="row gx-0 d-lg-flex">
            <div className="col-lg-7 px-5 text-start">
                <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                   <img src={logo} style={{width:"160px"}}/>
                   <h2 className='ms-4 fs-4 text-primary'>Sree Ramakrishna <br/> Bhajana Sabha Trust (Regd.)<br/>
                   <small className="fs-6 text-primary me-2">Uplifting Spirits, Serving Society.</small>  </h2> 
                                 
                </div>
                
               
            </div>
            <div className="col-lg-5 px-5 text-end">
                <div className="h-50 d-inline-flex align-items-center py-3 me-4">
                    <small className="fa fa-phone-alt text-primary me-2"></small>
                    <small>Ph: 9880111024 / 9854812545 <br/>
                        9945695670 / 8073748697</small>
                </div>
              
            </div>
        </div>
    </div>         */}
   
   

        </>
    );
  }
}