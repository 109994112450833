import React from 'react';
import axios from "axios";
import { withRouter } from 'react-router-dom';
import '../../assets/admin/css/login.css';
import '../../assets/admin/css/admin.scss';
import GetActivitiesTable from '../admin/getActivitiesTable';
class UploadMeta extends React.Component {
  constructor() {
    super();
    this.state = {
      refershTable:false,
      meta:"", 
      keywords:"", 
      description:"",
    analytics:"",
       isvilated:false,
       isdisableBtn:true,
       AddMetatag:[]};
  }

     // Handle input change
     handleInputChange = (e) => {
      
      const { name, value } = e.target;
      this.setState({ [name]: value },()=>{
           if(this.state.isvilated){
              this.validation();
           }
          
        });
  };  
  handleSubmit = (e) => {
      e.preventDefault();
      if(this.validation()){
                 
          const { meta,keywords,description,analytics} = this.state;
  
          // Create a FormData object to hold the data as form-data (multipart/form-data)
          const formData = new FormData();
          formData.append('meta', meta);
          formData.append('keywords', keywords);
          formData.append('description', description);
          formData.append('analytics',analytics);
        
      
          axios.post('https://trust.sharavisual.com/api/AddMetatags.php', formData, {
              headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then((response) => {
              console.log(response.data);
              
              if (response.data.status) {
                   this.setState({meta:'',
                      keywords:'',
                      description:'',
                      analytics: '',                     
                      isdisableBtn:true,isvilated:false,
                      refershTable:true},()=>{
                           
                        //this.getActivitiesList();
                        this.fetchItems();
                      })
                  //alert(response.message);
              } else {
                  alert('Failed to add contact: ' + response.data.message);
              }
          })
          .catch((error) => {
              console.error("Error:", error);
          });

      }else{
        alert('Please fill Activitie Name,Date,Time and Place');
      }
     
  };
  
   validation=()=>{
      
      let flag =true;
     
      
      if(this.state.meta==''){
          flag=false;
      }
      if(this.state.keywords==''){
          flag=false;
      }
      if(this.state.description==''){
          flag=false;
      }
      if(this.state.analytics==''){
        flag=false;
    }
      
     
      this.setState({isdisableBtn: (flag == true ? true : false),isvilated:true})
      return flag
   }  
   
   getAddMetatag=()=>{
    
    fetch('https://trust.sharavisual.com/api/getMeta.php')
    .then(response => { 
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      if (data.status) {
        this.setState({ AddMetatag: data.data });
        console.log('Fetched data:', data);
      } else {
        console.error('Error from API:', data.message);
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
   
    } 


    fetchItems = () => {
      
      axios.get('https://trust.sharavisual.com/api/AddMetatags.php')
          .then(response => {
              this.setState({ AddMetatag: response.data.data });
          })
          .catch(error => console.error("There was an error fetching the items!", error));
  };

 // Delete an item
 deleteItem = (id) => {
    debugger;
  axios.delete(`https://trust.sharavisual.com/api/AddMetatags.php?id=${id}`)
      .then(() => {
          this.fetchItems();
      })
      .catch(error => console.error("There was an error deleting the item!", error));
};

// Edit item
editItem = (id) => {
  axios.get(`https://trust.sharavisual.com/api/AddMetatags.php?id=${id}`)
          .then(response =>                
            {
                if(response.data.status){
                  let obj = response.data.data[0]
                  this.setState({
                    editId:obj.id,
                    meta:obj.meta,
                    keywords:obj.keywords,
                    description:obj.description,
                    analytics:obj.analytics
                    });
                }
             
          })
          .catch(error => console.error("There was an error fetching the items!", error));
  
};

// Update item
updateItem = () => {
  debugger;
  const { editId, meta, description, keywords, analytics } = this.state;
  axios
    .put('https://trust.sharavisual.com/api/AddMetatags.php', { 
      id: editId, // Include 'id' in the body
      meta: meta,
      keywords: keywords,
      description: description,
      analytics: analytics
    })
    .then(response => {
      debugger;
      if (response.status) {
        this.setState({
          editId: '',
          meta: '',
          keywords: '',
          description: '',
          analytics: ''
        });
        this.fetchItems();
      }
    })
    .catch(error => console.error("There was an error updating the item!", error));
};




componentDidMount(){
  this.fetchItems();
}

  render() {
    return (
         <>
    
          <div class="row">
            <div class="col-6 col-lg-6 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Add MetaTags and Keywords</h4>
                 
                  <form class="forms-sample">
                    <div class="form-group">
                      <label for="activitieName">Site Title <span className='text-danger'>*</span></label>
                      <input type="text" class="form-control" id="meta" name="meta" value={this.state.meta} onChange={this.handleInputChange} placeholder="Site Title"></input>
                    </div>
                    <div class="form-group w-100">
                      <label for="date">Keywords</label>
                      <textarea type="text" class="form-control" id="date" name="keywords" value={this.state.keywords} onChange={this.handleInputChange} placeholder="Site Keywords" rows="4" style={{minHeight: "5rem"}}></textarea>
                    </div>
                    <div class="form-group w-100">
                      <label for="time">Description</label>
                      <textarea type="text" class="form-control" id="time" name="description" value={this.state.description} onChange={this.handleInputChange} placeholder="Site Description" rows="4" style={{minHeight: "5rem"}}></textarea>
                    </div>
                    <div class="form-group">
                      <label for="place">Analytics Code</label>
                      <input class="form-control" id="place" name="analytics" value={this.state.analytics} onChange={this.handleInputChange} placeholder="UTC-XXX" ></input>
                    </div>
                    <button
        type="submit"
        className="btn btn-primary me-2 mt-2"
        onClick={this.state.editId ? this.updateItem : this.handleSubmit} disabled={this.state.AddMetatag.length > 0 ? (this.state.editId ? false :true ): false}>
        {this.state.editId ? "Update" : "Add"}</button>
                  </form>
                </div>
              </div>
            </div>
            
            <div class="col-6 col-lg-6 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">MetaTags and Keywords</h4>
                 

                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th>Site Title</th>
                          <th>Keywords</th>
                          <th>Description</th>
                          <th>Analytics</th>
                          <th>Edit</th>
                          <th>Delete</th>                          
                        </tr>
                      </thead>
                      <tbody>
                         {this.state.AddMetatag.length>0 && this.state.AddMetatag.map((item,index)=>{
                          return(
                          <tr>
                          <td>{item.meta}</td>
                          <td>{item.keywords}</td>
                          <td>{item.description}</td>
                          <td>{item.analytics}</td>
                          <td><button className='btn btn-primary' onClick={()=>this.editItem(item.id)}>Edit</button></td>
                          <td><button className='btn btn-primary' onClick={()=>this.deleteItem(item.id)}>Delete</button></td>
                          </tr>
                          )
                         })}
                        
                       
                      </tbody>
                    </table>
                  </div>
                 
               
                </div>
              </div>
            </div>
          </div>
       
         </>
    );
  }
}
export default UploadMeta;