import React from 'react';
import HomeContent from '../component/HomeContent';
export default class Home extends React.Component {
  constructor() {
    super();
    // this.state = {color: "red"};
  }
  render() {
    return (
         <>
         <HomeContent/>
         </>
    );
  }
}