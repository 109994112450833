//import logo from './logo.svg';

import React, { Component } from 'react';
import Layout from './layout/layout.js';
import './App.css';

function App() {
  return (
      <Layout/>
  );
}

export default App;
