import React from 'react';
import { withRouter ,Redirect } from 'react-router-dom';
import axios from "axios";
import '../../assets/admin/css/login.css';
import '../../assets/admin/css/admin.scss';
class Admin extends React.Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      isLoggedIn: false,
      error: ''
    };
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleLogin = (e) => {
        e.preventDefault();
        debugger;    
// Make an API call to authenticate the user
axios
.post("https://trust.sharavisual.com/api/login.php", {
  username:this.state.username,
  password:this.state.password
})
.then((response) => {
  debugger;
  const { data } = response;
  if (data.status) {
    // Store user session (token, user info) in localStorage
    sessionStorage.setItem('isLoggedIn', 'true'); 
    //localStorage.setItem('isLoggedIn', 'true');
    //localStorage.setItem("user", JSON.stringify(data.user));
    //alert("Login successful!");
    // Redirect to a new page (e.g., dashboard)
    this.setState({ isLoggedIn: true });
    //this.props.history.push("/GetInvolvedList");
  } else {
    this.setState({ error: data.message });
  }
})
.catch((error) => {
  console.error("There was an error logging in!", error);
  this.setState({ error: "Login failed. Please try again." });
});
    
  };
  
  render() {
    debugger;
    if (sessionStorage.getItem("isLoggedIn")) {
      return <Redirect to="/GetInvolvedList" />; // Redirect to dashboard upon login
    }
    return (
         <>
    <div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <h4>Admin</h4>
              <h6 class="fw-light">Sign in to continue.</h6>
              <form class="pt-3">
                <div class="form-group">
                  <input type="email" class="form-control form-control-lg" id="username" name="username" value={this.state.username}
                  onChange={this.handleInputChange} placeholder="Username"></input>
                </div>
                <div class="form-group">
                  <input type="password" class="form-control form-control-lg" id="password" name="password" value={this.state.password}
                  onChange={this.handleInputChange} placeholder="Password"></input>
                </div>
                <div class="mt-3">
                  <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" onClick={this.handleLogin}>SIGN IN</button>
                </div>
                
              </form>
              {this.state.error && <p style={{ color: 'red' }}>{this.state.error}</p>}
            </div>
          </div>
        </div>
      </div>     
    </div>    
  </div>
         </>
    );
  }
}
export default withRouter(Admin);